import 'firebase/auth'
import {
  browserSessionPersistence,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailLink,
} from 'firebase/auth'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Dialog } from 'primereact/dialog'
import { FloatLabel } from 'primereact/floatlabel'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSigninCheck } from 'reactfire'
import useFbapiInstance from '../Hooks/useFbapiInstance'
import useFreshService from '../Hooks/useFreshService'
import { Page } from './Page'

export default function SignIn() {
  const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const auth = getAuth()
  const navigate = useNavigate()
  const [showLogin, setShowLogin] = useState(false)
  const { RequestUserAccount } = useFreshService()
  const [requesting, setRequesting] = useState(false)
  const [sending, setSending] = useState(false)
  const [open, setOpen] = useState(false)
  const [isOpenWithEmailLink] = useState(isSignInWithEmailLink(auth, window.location.href))

  const { api } = useFbapiInstance()

  const toast = useRef<Toast>(null)

  const { data, status } = useSigninCheck()

  useEffect(() => {
    console.log('status use effect', data, status)
    if (data) {
      if (data.signedIn) {
        navigate('/search')
      } else setShowLogin(true)
    }
  }, [data])

  const handleRequestAccount = () => {
    setSending(true)
    const fullName = firstname + ' ' + lastname
    RequestUserAccount.mutate(
      { name: fullName, email: email },
      {
        onSuccess: () => {
          toast.current?.show({ severity: 'success', summary: 'Success!', detail: '' })
          setSending(false)
          setOpen(true)
        },
        onError: () => {
          console.log('Error creating account:', RequestUserAccount.error)
          setSending(false)
          toast.current?.show({ severity: 'error', summary: 'Error!', detail: RequestUserAccount.error?.message ?? 'Contact Support' })
        },
      }
    )
  }

  const validateCreateUser = () => {
    return !firstname || !lastname || !emailRegex.test(email)
  }

  useEffect(() => {
    console.log('isSignInWithEmailLink', isOpenWithEmailLink)
    if (!isOpenWithEmailLink) return
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.

    const emailFromLocal = window.localStorage.getItem('emailForSignIn')
    if (!emailFromLocal) {
      if (toast.current !== null) {
        ;(toast.current as Toast).clear()
        ;(toast.current as Toast).show({
          severity: 'warn',
          summary: 'Warning',
          detail: 'Link has already been used! Please sign in again to request a new link.',
        })
      }
    } else {
      // The client SDK will parse the code from the link for you.
      setPersistence(auth, browserSessionPersistence).then(() => {
        signInWithEmailLink(auth, emailFromLocal, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            console.log('signInWithEmailLink result', result)
            navigate('/search')
          })
          .catch((error) => {
            console.log('error', error)
            ;(toast.current as Toast).clear()
            if (toast.current !== null) {
              ;(toast.current as Toast | null)?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' })
            }
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          })
      })
    }
  }, [isOpenWithEmailLink])

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: process.env.REACT_APP_SITE_URL!,
    // This must be true.
    handleCodeInApp: true,
  }

  const handleSignIn = async () => {
    if (!email) return
    setSending(true)
    const canThey = await canUserLogin()
    if (!canThey) {
      if (toast.current !== null) {
        ;(toast.current as Toast | null)?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Unknown Email. Request an account from home page!',
        })
      }
      setSending(false)
      return
    } else {
      setPersistence(auth, browserSessionPersistence).then(() => {
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email)
            if (toast.current !== null) {
              ;(toast.current as Toast | null)?.show({ severity: 'info', summary: 'Info', detail: 'Email sent!' })
            }
            setSending(false)
            setOpen(true)
          })
          .catch((error) => {
            console.log('error', error)

            if (toast.current !== null) {
              ;(toast.current as Toast | null)?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' })
            }

            setSending(false)
          })
      })
    }
  }

  async function userExists(email: string | undefined): Promise<boolean> {
    if (!email) return false
    try {
      const response = await api.get(`user?email=${email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log('response', response)
      return response.status === 200
    } catch (error) {
      return false
    }
  }

  //when a user adds their email, verify they already are a user otherwise we tell them to request an account
  //prevents tokens from being provided before verified by EUS team and 'provisioned'
  //skip if running emulators
  const canUserLogin = () => (process.env.NODE_ENV == 'development' ? true : userExists(email))

  return (
    <Page title="">
      {/* {status == 'loading' && <ProgressSpinner />} */}
      {showLogin && (
        <div style={{ margin: 'auto', maxWidth: '35rem', minWidth: '25vw' }}>
          <Card title="Welcome" className="flex flex-wrap justify-content-center gap-2">
            {requesting ? (
              <>
                <div className="col-12 pt-3">
                  <FloatLabel>
                    <InputText
                      name="firstname"
                      id="firstname"
                      style={{ width: '100%' }}
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <label htmlFor="firstname">First Name</label>
                  </FloatLabel>
                </div>
                <div className="col-12 pt-3">
                  <FloatLabel>
                    <InputText
                      name="lastname"
                      id="lastname"
                      style={{ width: '100%' }}
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                    <label htmlFor="lastname">Last Name</label>
                  </FloatLabel>
                </div>
                <div className="col-12 pt-3">
                  <FloatLabel>
                    <InputText
                      style={{ width: '100%' }}
                      name="email"
                      id="email"
                      invalid={email != '' && !emailRegex.test(email)}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email</label>
                  </FloatLabel>
                </div>
                {/* <div className="col-12 pt-3 ">
                  <FloatLabel>
                    <InputText style={{ width: '100%' }} id="payer" value={payor} onChange={(e) => setPayor(e.target.value)} />
                    <label htmlFor="payer">Company</label>
                  </FloatLabel>
                </div> */}
                <div className="col-12 pt-3 flex justify-content-center ">
                  <Button disabled={validateCreateUser()} loading={RequestUserAccount.isLoading} onClick={handleRequestAccount}>
                    {sending ? '' : 'Submit'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 pt-3">
                  <FloatLabel>
                    <InputText
                      style={{ width: '100%' }}
                      name="email"
                      id="email"
                      invalid={email != '' && !emailRegex.test(email)}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email</label>
                  </FloatLabel>
                </div>
                <div className="col-12 pt-3 flex justify-content-between align-items-center">
                  <Button loading={sending} onClick={handleSignIn} disabled={!emailRegex.test(email ?? '')}>
                    {sending ? '' : 'Sign In'}
                  </Button>
                  <div className="mx-3"> OR </div>
                  <Button onClick={() => setRequesting(true)}>Request Account</Button>
                </div>
              </>
            )}
          </Card>
        </div>
      )}
      <Toast ref={toast} />
      <Dialog header={'Sent!'} visible={open} style={{ width: '450px' }} modal onHide={() => setOpen(false)}>
        <div className="p-d-flex justify-content-center">
          {requesting ? (
            <>
              <p>Your request has been submitted</p>
              <p>You'll recieve confirmation once your account has been appropriately provisioned</p>
              <hr />
              <p>
                <i>You can safely close this window</i>
              </p>
            </>
          ) : (
            <>
              <p>Check your inbox for a sign in link</p>
              <p>You may need to check spam folders</p>
              <hr />
              <p>If assistance is needed, use the Help page to submit a ticket</p>
              <hr />
              <p>
                <i>You can safely close this window</i>
              </p>
            </>
          )}
        </div>
      </Dialog>
    </Page>
  )
}
