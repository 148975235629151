import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import useWbapiInstance from './useWbapiInstance'

export default function useFreshService() {
  const { api } = useWbapiInstance()

  const RequestUserAccount = useMutation<Number, AxiosError, { name: string; email: string }>((request) =>
    api
      .post(`/api/FreshService/PayorPortalUserCreationRequest?name=${request.name}&email=${request.email}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => resp.data)
  )

  const RequestHelp = useMutation<Number, AxiosError, { requestor: string | null | undefined; description: string }>((request) =>
    api
      .post(`/api/FreshService/PayorPortalHelpRequest?requestor=${request.requestor}&description=${request.description}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => resp.data)
  )

  return {
    RequestUserAccount,
    RequestHelp,
  }
}
